<template>
    <validation-provider v-slot="validator" :name="field.label" :rules="rules" :style="`--vue-form-field-cols: ${columns}`">
        <form-field :errors="validator.errors">
            <gw-label
                class="gw-form-field__label"
                :for="field.handle"
            >
                {{ field.label }}
            </gw-label>
            <dn-textarea
                v-model="value"
                :validator="validator"
                :required="field.required"
                :clearable="false"
                rows="4"
                :name="field.handle"
            />
        </form-field>
    </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import DnTextarea from '~/patterns/atoms/textarea/textarea';
import GwLabel from '~/patterns/atoms/label/label';
import FormField from '~/patterns/molecules/form-field/form-field.vue';

export default {
    components: {
        ValidationProvider,
        DnTextarea,
        GwLabel,
        FormField
    },
    props: {
        field: {
            type: Object,
            required: true
        },
        columns: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            value: ''
        };
    },
    computed: {
        rules() {
            return this.field.required ? 'required' : null;
        }
    },
    mounted() {
        this.value = this.field.value;
    }
};
</script>
