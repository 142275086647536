<template>
    <div>
        <dn-button
            class="dn-button--large"
            :class="loading ? 'dn-button--loading' : ''"
            type="submit"
            :style="`--vue-form-field-cols: ${dataColumns || columns}`"
        >
            <span class="button__label">{{ field.labelNext }}</span>
        </dn-button>
    </div>
</template>

<script>
import { get } from 'lodash';
import DnButton from '~/patterns/atoms/button/button.vue';

export default {
    components: {
        DnButton,
    },
    props: {
        field: {
            type: Object,
            required: true
        },
        columns: {
            type: Number,
            required: true
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    computed: {
        dataColumns() {
            return get(this, 'field.inputAttributes.0.value');
        }
    }
};
</script>
