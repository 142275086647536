<template>
    <gw-section
        layout="call-to-action"
        width="content"
    >
        <template v-if="heading" slot="title">{{ heading }}</template>
        <gw-button
            class="dn-button--large"
            element="a"
            :href="url()"
            :target="isExternal() ? '_blank' : '_self'"
        >
            {{ label }}
        </gw-button>
    </gw-section>
</template>

<script>
import GwSection from '~/patterns/organisms/section/section';
import GwButton from '~/patterns/atoms/button/button';

export default {
    components: {
        GwSection,
        GwButton,
    },

    props: {
        label: {
            type: String,
            default: null
        },

        externalLink: {
            type: String,
            default: null
        },

        internalLink: {
            type: Array,
            default: () => { return []; }
        },

        asset: {
            type: Array,
            default: () => { return []; }
        },

        heading: {
            type: String,
            required: false,
            default: null
        }
    },

    methods: {
        isExternal() {
            return !this.internalLink.length;
        },
        url() {
            if (this.asset.length) {
                return this.asset[0].url;
            }

            return this.isExternal() ? this.externalLink : this.internalLink[0].url;
        }
    }
};
</script>
