<template>
    <gw-section width="page">
        <gw-quote>
            {{ quote }}
            <template v-if="author" slot="author">
                {{ author }}
            </template>
        </gw-quote>
    </gw-section>
</template>

<script>
import GwSection from '~/patterns/organisms/section/section.vue';
import GwQuote from '~/patterns/atoms/quote/presets/pullquote.vue';

export default {
    components: {
        GwSection,
        GwQuote
    },

    props: {
        quote: {
            type: String,
            required: true,
            default: null
        },
        author: {
            type: String,
            required: false,
            default: null
        }
    },
};
</script>
