<template>
    <gw-card
        class="gw-program-card"
        :href="localePath(`/courses/${program.slug}`)"
        :data-category="program.slug"
    >
        <template slot="default">
            <h5 v-if="!!program.label" class="gw-program-card__subtitle">
                {{ program.label }}
            </h5>

            <h3 class="gw-program-card__title">
                {{ program.title }}
            </h3>

            <slot />
        </template>

        <template slot="after">
            <div class="gw-program-card__tag">
                <span v-if="count > 0">{{ count }}</span> <span v-if="count > 1">{{ $t('home.courses') }}</span><span v-else>{{ $t('home.course') }}</span>
            </div>
        </template>
    </gw-card>
</template>

<script>
import GwCard from '~/patterns/atoms/card/card.vue';
import countPages from '~/graphql/queries/countPages.graphql';

export default {
    components: {
        GwCard,
    },
    props: {
        program: {
            type: Object,
            default: () => { return {}; }
        }
    },
    data() {
        return {
            count: 0,
        };
    },
    async fetch() {
        const site = this.$config.appMultisiteHandle,
            { data } = await this.$gql.executeQuery(
                countPages,
                { site, type: 'coursePage', programs: this.program.id }
            );

        this.count = data.entryCount;
    }
};
</script>

<style src="./program-card.less" lang="less"></style>
