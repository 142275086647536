<template>
    <gw-section
        class="gw-editions-schedule"
        width="page"
        :class="{
            'gw-editions-schedule--dark': background === 'primary',
            'gw-editions-schedule--accent': background === 'accent'
        }"
    >
        <gw-section>
            <template v-if="heading" slot="title">
                {{ heading }}
            </template>
        </gw-section>

        <gw-editions-schedule :courses="courses" :trainers="trainers" />
    </gw-section>
</template>

<script>
import GwSection from '~/patterns/organisms/section/section.vue';
import GwEditionsSchedule from '~/patterns/organisms/editions-schedule/editions-schedule';

export default {
    components: {
        GwEditionsSchedule,
        GwSection,
    },

    props: {
        heading: {
            type: String,
            default: null
        },
        courses: {
            type: Array,
            default: () => {
                return [];
            }
        },
        trainers: {
            type: Array,
            default: () => {
                return [];
            }
        },
        width: {
            type: String,
            default: 'page',
        },
        background: {
            type: String,
            default: 'accent',
        }
    }
};
</script>

<style lang="less" src="./editions-schedule.less"/>
