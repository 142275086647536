<template>
    <gw-section v-if="richText" width="content" background="none">
        <template v-if="heading" slot="title">{{ heading }}</template>
        <rich-text :text="richText || undefined" />
    </gw-section>
</template>

<script>
import GwSection from '~/patterns/organisms/section/section.vue';
import RichText from '~/patterns/molecules/rich-text/rich-text.vue';

export default {
    components: {
        RichText,
        GwSection
    },

    props: {
        richText: {
            type: String,
            required: true,
            default: null
        },

        heading: {
            type: String,
            required: false,
            default: null
        }
    }
};
</script>
