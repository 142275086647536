<template>
    <div
        v-if="enableDragToScroll"
        v-dragscroll
        class="drag-to-scroll"
        :class="(dragging) ? 'is-dragging' : ''"
        @dragscrollstart="handleDragStart()"
        @click.capture="onClickCapture"
    >
        <slot />
    </div>
    <div v-else>
        <slot />
    </div>
</template>

<script>
'use strict';

export default {
    data() {
        return {
            dragging: false,
            dragTimeout: null,
            supportsTouch: false
        };
    },

    computed: {
        enableDragToScroll() {
            return !this.supportsTouch;
        }
    },

    mounted() {
        if ('ontouchstart' in window || navigator.msMaxTouchPoints) {
            this.supportsTouch = true;
        }
    },

    methods: {
        handleDragStart() {
            clearTimeout(this.dragTimeout);

            this.dragged = false;
            this.dragTimeout = setTimeout(() => { this.dragged = true; }, 100); // Minimal delay to be regarded as drag instead of click
        },
        onClickCapture(e) {
            if (this.dragged) {
                e.preventDefault();
            }

            this.dragged = false;
        }
    }
};
</script>

<style lang="less" src="./drag-to-scroll.less"></style>
