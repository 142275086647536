<template>
    <div
        class="section"
        :data-layout="layout"
        :data-orientation="orientation"
    >
        <gw-page-container
            class="section__container"
            :class="{
                'gw-page-container--content': width === 'content',
                'gw-page-container--bleed': width === 'page',
            }"
        >
            <div
                v-if="!!$slots.title && !!$slots.button"
                class="section__header"
            >
                <h2 class="section__title">
                    <slot name="title" />
                </h2>
                <div class="hidden-mobile">
                    <slot name="button" />
                </div>
            </div>

            <template v-else>
                <h2
                    v-if="!!$slots.title"
                    class="section__title"
                >
                    <slot name="title" />
                </h2>
            </template>

            <div
                v-if="!!$slots.aside"
                class="section__aside"
            >
                <slot name="aside" />
            </div>

            <div class="section__body">
                <slot />
            </div>

            <div
                v-if="!!$slots.button"
                class="section__footer hidden-desktop"
            >
                <slot name="button" />
            </div>
        </gw-page-container>
    </div>
</template>

<script>
import GwPageContainer from '~/patterns/molecules/page-container/page-container';

export default {
    components: {
        GwPageContainer
    },

    props: {
        layout: {
            type: String,
            default: 'default',
            validator(value) {
                return ['default', 'text-image', 'video', 'home-intro', 'rich-text', '2-columns', 'call-to-action'].includes(value);
            }
        },

        orientation: {
            type: String,
            default: 'left',
            validator(value) {
                return ['left', 'right'].includes(value);
            }
        },

        width: {
            type: String,
            default: '',
            validator(value) {
                return ['content', 'page', ''].includes(value);
            }
        }
    }
};
</script>

<style lang="less" src="./section.less" />
