<template>
    <div v-if="hasNextPage" class="gw-pagination">
        <gw-icon icon="arrow-down" class="gw-icon--blue" />

        <a @click="getNextPage()">{{ $t('schedule.show-next', { count: pageSize }) }}</a>

        <div>{{ $t('schedule.of-total', { count: totalCount }) }}</div>
    </div>
</template>

<script>
import GwIcon from '~/patterns/atoms/icon/icon';

export default {
    components: {
        GwIcon,
    },
    props: {
        currentCount: {
            type: Number,
            default: 0,
        },
        totalCount: {
            type: Number,
            default: 0,
        },
        pageSize: {
            type: Number,
            default: 0,
        }
    },
    computed: {
        hasNextPage() {
            return this.currentCount < this.totalCount;
        }
    },
    methods: {
        getNextPage() {
            this.$emit('getNextPage');
        }
    }
};
</script>

<style lang="less" src="./pagination.less" />
