<template>
    <div class="gw-stat-slab">
        <div v-if="above" class="gw-stat-slab__label">
            <slot name="label" />
        </div>
        <div class="gw-stat-slab__data">
            <slot name="data" />
        </div>
        <div v-if="!above" class="gw-stat-slab__label">
            <slot name="label" />
        </div>
    </div>
</template>

<script>
export default {
    props: {
        labelPosition: {
            type: String,
            default: 'top',
            validator(value) {
                return ['top', 'bottom'].includes(value);
            }
        }
    },
    computed: {
        above() {
            return this.labelPosition === 'top';
        }
    }
};
</script>

<style src="./stat-slab.less" lang="less"></style>
