<template>
    <gw-text-image :image-fit="imageFit" :float="false" v-bind="$attrs">
        <div slot="title" class="text-image-cta__title">
            <slot name="title" />
        </div>
        <slot />
        <div slot="button" class="text-image-cta__button">
            <slot name="button" />
        </div>
    </gw-text-image>
</template>

<script>
import GwTextImage from '~/patterns/molecules/text-image/text-image.vue';
export default {
    components: {
        GwTextImage
    },
    props: {
        imageFit: {
            type: String,
            default: 'cover'
        }
    }
};
</script>

<style src="./text-image-cta.less" lang="less"></style>
