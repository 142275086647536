<template>
    <blockquote class="gw-quote">
        <slot />
    </blockquote>
</template>

<script>
export default {
};
</script>

<style lang="less" src="./quote.less"></style>
