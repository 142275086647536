<template>
    <div
        v-if="image || richText"
        class="text-image"
        :class="{
            'text-image--float': float,
            'text-image--grid': !float
        }"
        :data-orientation="imageAlignment"
    >
        <template v-if="float">
            <lazy-img
                v-if="image"
                class="text-image__image"
                :src="image.url"
                :src-tiny="image.url_tiny"
                :height="image.height"
                :width="image.width"
                :caption="caption"
                max="768"
                sizes="370px"
                alt=""
            />
            <slot name="title" />
            <rich-text class="text-image__text" :text="richText || undefined" />
            <slot name="button" />
        </template>
        <template v-else>
            <lazy-img
                v-if="image && imageAlignment === 'left'"
                class="text-image__image"
                :class="{
                    'lazy-img--cover': imageFit === 'cover',
                    'lazy-img--contain': imageFit === 'contain'
                }"
                :src="image.url"
                :src-tiny="image.url_tiny"
                :height="image.height"
                :width="image.width"
                :caption="caption"
                max="768"
                sizes="370px"
                alt=""
            />

            <div>
                <slot name="title" />
                <rich-text class="text-image__text" :text="richText || undefined" />
                <slot name="button" />
            </div>

            <lazy-img
                v-if="image && imageAlignment === 'right'"
                class="text-image__image"
                :class="{
                    'lazy-img--cover': imageFit === 'cover',
                    'lazy-img--contain': imageFit === 'contain'
                }"
                :src="image.url"
                :src-tiny="image.url_tiny"
                :height="image.height"
                :width="image.width"
                :caption="caption"
                max="768"
                sizes="370px"
                alt=""
            />
        </template>
    </div>
</template>

<script>
import RichText from '~/patterns/molecules/rich-text/rich-text.vue';
import LazyImg from '~/patterns/atoms/lazy-img/lazy-img.vue';

export default {
    components: {
        RichText,
        LazyImg
    },

    props: {
        float: {
            type: Boolean,
            default: true
        },
        image: {
            type: Object,
            required: false,
            default: () => { return {}; }
        },
        richText: {
            type: String,
            required: false,
            default: ''
        },
        caption: {
            type: String,
            required: false,
            default: ''
        },
        imageAlignment: {
            type: String,
            default: 'right'
        },
        imageFit: {
            type: String,
            default: 'cover'
        }
    }
};
</script>

<style lang="less" src="./text-image.less"></style>
