<template>
    <gw-default-template class="template--dark-mode">
        <IntersectionAnchor />

        <slot name="hero" />

        <slot name="title" />

        <slot />

        <slot name="modals" />
    </gw-default-template>
</template>

<script>
import GwDefaultTemplate from '~/patterns/templates/default.vue';
import IntersectionAnchor from '~/patterns/atoms/intersection-anchor/presets/header';

export default {
    components: {
        GwDefaultTemplate,
        IntersectionAnchor
    }
};
</script>
