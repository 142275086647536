<template>
    <gw-inline-navigation class="sticky sticky--inline-navigation">
        <nav class="gw-inline-navigation__nav sticky__element">
            <div class="gw-inline-navigation__outer-container">
                <div class="gw-inline-navigation__inner-container">
                    <div class="gw-inline-navigation__link-wrapper">
                        <a
                            v-for="(navigationItem, key) in children"
                            :key="key"
                            v-scroll-to="{
                                el: `#${navigationItem.anchor}`,
                                offset: -200
                            }"
                            class="gw-inline-navigation__link"
                            :class="{
                                'gw-inline-navigation__link--active': activeItem === navigationItem.anchor
                            }"
                        >
                            {{ navigationItem.label }}
                        </a>
                    </div>
                </div>
            </div>
        </nav>

        <div class="gw-inline-navigation__content">
            <template v-for="(navigationItem, key) in children">
                <gw-inline-navigation-item
                    :key="key"
                    v-view="viewHandler"
                    :children="navigationItem.children"
                    :label="navigationItem.label"
                    :anchor="navigationItem.anchor"
                />
            </template>
        </div>
    </gw-inline-navigation>
</template>

<script>
import GwInlineNavigationItem from './inline-navigation-item';
import GwInlineNavigation from '~/patterns/molecules/inline-navigation/inline-navigation.vue';

export default {
    components: {
        GwInlineNavigation,
        GwInlineNavigationItem,
    },
    props: {
        children: {
            type: Array,
            required: true,
            default: () => { return []; }
        },
    },
    data() {
        return {
            activeItem: null
        };
    },
    methods: {
        viewHandler(event) {
            if (event.percentInView > 0.8) {
                this.activeItem = event.target.element.id;
            }
        }
    }
};
</script>
