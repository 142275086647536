<template>
    <div class="vue-form-fields">
        <div v-for="row in rows" :key="row.id" class="vue-form-fields__row">
            <template v-for="field in row.fields">
                <component
                    :is="getFieldName(field)"
                    v-if="checkConditional(field)"
                    :key="field.id"
                    :field="field"
                    class="vue-form-fields__item"
                    :columns="12 / row.fields.length || 12"
                    :loading="loading"
                />
            </template>
        </div>
        <div v-if="$slots.errors || $slots.messages" class="vue-form-fields__row">
            <slot name="errors" />
            <slot name="messages" />
        </div>
    </div>
</template>

<script>
import { flatMap } from 'lodash';
import textField from '~/patterns/atoms/freeform/text.vue';
import richtextField from '~/patterns/atoms/freeform/rich-text.vue';
import emailField from '~/patterns/atoms/freeform/email.vue';
import textareaField from '~/patterns/atoms/freeform/textarea.vue';
import radiogroupField from '~/patterns/atoms/freeform/radio-group.vue';
import checkboxgroupField from '~/patterns/atoms/freeform/checkbox-group.vue';
import submitField from '~/patterns/atoms/freeform/submit.vue';
import checkboxField from '~/patterns/atoms/freeform/checkbox.vue';
import fileField from '~/patterns/atoms/freeform/file.vue';
import numberField from '~/patterns/atoms/freeform/number.vue';
import selectField from '~/patterns/atoms/freeform/select.vue';
import editionSelectField from '~/patterns/atoms/freeform/edition-select.vue';
import phoneField from '~/patterns/atoms/freeform/phone.vue';
import hiddenField from '~/patterns/atoms/freeform/hidden.vue';

export default {
    components: {
        textField,
        richtextField,
        radiogroupField,
        checkboxgroupField,
        textareaField,
        emailField,
        submitField,
        checkboxField,
        fileField,
        numberField,
        selectField,
        editionSelectField,
        phoneField,
        hiddenField
    },
    props: {
        rows: {
            type: Array,
            default: () => { return []; }
        },
        loading: {
            type: Boolean,
            default: false
        }
    },
    methods: {
        getFieldName(field) {
            const foundAttribute = field.inputAttributes.find((attribute) => {
                return attribute.key === 'dont-show';
            });

            if (foundAttribute) {
                return 'hiddenField';
            }

            const fieldName = field.__typename;

            if (field.handle === 'editionSelect') {
                return 'editionSelectField';
            }

            return `${fieldName.replace('FreeformField_', '').toLowerCase()}Field`;
        },
        checkConditional(field) {
            const foundAttribute = field.inputAttributes.find((attribute) => {
                return attribute.key === 'conditional';
            });

            if (!foundAttribute) {
                return true;
            }

            const [handle, value] = foundAttribute.value.split(':');

            const foundField = flatMap(this.rows, row => row.fields || []).find(rowField => rowField.handle === handle);
            if (foundField.type === 'checkbox_group' || foundField.type === 'radio_group') {
                const foundOption = foundField.options.find(option => option.value === value && option.checked === true);

                return !!foundOption;
            }

            return true;
        }
    }
};
</script>

<style lang="less" src="./vue-form-fields.less" />
