<template>
    <div>
        <gw-label class="form-label">{{ field.label }}</gw-label>
        <gw-multiselect v-model="value" :options="field.options" track-by="label" label="label">
            <template slot="caret">
                <gw-icon class="gw-filterselect__caret" icon="chevron-down" />
            </template>
        </gw-multiselect>
    </div>
</template>

<script>
import GwMultiselect from '~/patterns/atoms/multiselect/multiselect.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';
import GwLabel from '~/patterns/atoms/label/label.vue';

export default {
    components: {
        GwMultiselect,
        GwIcon,
        GwLabel
    },
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            value: ''
        };
    }
};
</script>
