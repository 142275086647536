<template>
    <div class="gw-program-filters">
        <div class="gw-program-filters__title">{{ $t('schedule.programs') }}</div>
        <div v-if="!!programs" class="gw-program-filters__wrapper">
            <gw-button
                v-for="program in programs"
                :key="program.id"
                class="gw-program-filters__item dn-button--large"
                :class="{
                    'dn-button--transparent': !checkActive(program.id)
                }"
                :data-category="program.slug"
                @click="filterProgram(program)"
            >
                <gw-category
                    :class="{
                        'gw-category--active': checkActive(program.id)
                    }"
                    :data-category="program.slug"
                >
                    {{ program.title }}
                </gw-category>
            </gw-button>
        </div>
    </div>
</template>

<script>
import GwButton from '~/patterns/atoms/button/button.vue';
import GwCategory from '~/patterns/atoms/category/category.vue';
export default {
    components: {
        GwButton,
        GwCategory
    },
    props: {
        programs: {
            type: Array,
            default: () => { return []; }
        },
        selectedProgram: {
            type: Object,
            default: () => { return {}; }
        }
    },
    methods: {
        filterProgram(program) {
            if (this.checkActive(program.id)) {
                this.$emit('filterChanged', {});
            } else {
                this.$emit('filterChanged', program);
            }
        },
        checkActive(id) {
            const selected = this.selectedProgram || { id: null };
            return id === selected.id;
        }
    }
};
</script>

<style src="./program-filters.less" lang="less"></style>
