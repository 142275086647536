<template>
    <gw-trainer-list :trainers="trainers">
        <template slot="title">
            {{ heading }}
        </template>
        <template slot="link">
            {{ $t('home.all-trainers-link') }}
        </template>
    </gw-trainer-list>
</template>

<script>
import GwTrainerList from '~/patterns/organisms/trainer-list/trainer-list';

export default {
    components: {
        GwTrainerList,
    },

    props: {
        heading: {
            type: String,
            required: false,
            default: null
        },
        trainers: {
            type: Array,
            required: true,
            default: () => { return []; },
        }
    }
};
</script>
