<template>
    <multiselect
        class="gw-filterselect"
        v-bind="$attrs"
        v-on="$listeners"
    >
        <template slot="placeholder">
            <slot name="placeholder" />
        </template>

        <template slot="singleLabel" slot-scope="props">
            <span class="gw-filterselect__single-wrapper">
                {{ props.option.title }}
            </span>
        </template>

        <div slot="option" slot-scope="props" class="gw-filterselect__option">
            <div class="gw-filterselect__name">
                {{ props.option.title }}
            </div>
            <gw-icon class="gw-filterselect__check" icon="check" />
        </div>

        <template slot="caret">
            <gw-icon class="gw-filterselect__caret" icon="chevron-down" />
        </template>
    </multiselect>
</template>

<script>
import Multiselect from '~/patterns/atoms/multiselect/multiselect.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        Multiselect,
        GwIcon
    },
    data() {
        return {
            options: [],
        };
    }
};
</script>

<style src="./filterselect.less" lang="less"></style>
