<template>
    <gw-section>
        <a v-if="showButton" id="item-with-cta" />
        <template v-if="background === 'accent'">
            <gw-cta-card>
                <template slot="image">
                    <lazy-img
                        v-if="image[0]"
                        class="lazy-img--cover"
                        :src="image[0].url"
                        :src-tiny="image[0].url_tiny"
                        :height="image[0].height"
                        :width="image[0].width"
                        :caption="caption"
                        max="768"
                        sizes="370px"
                        alt=""
                    />
                </template>

                <template slot="default">
                    <h2 class="gw-card__title">{{ heading }}</h2>
                    <rich-text :text="richText || undefined" />
                </template>

                <gw-button
                    v-if="showButton"
                    slot="button"
                    class="dn-button--primary dn-button--large"
                    element="a"
                    :href="url()"
                    :target="isExternal() ? '_blank' : '_self'"
                >
                    {{ label }}
                    <gw-icon slot="after" icon="arrow-right" />
                </gw-button>
            </gw-cta-card>
        </template>
        <template v-else>
            <gw-text-image
                :image="image[0]"
                :caption="caption"
                :rich-text="richText"
                :image-alignment="imageAlignment"
                :image-fit="imageFit"
            >
                <template slot="title">
                    <h2>{{ heading }}</h2>
                </template>
                <template
                    v-if="showButton"
                    slot="button"
                >
                    <gw-button
                        class="dn-button--primary dn-button--large"
                        element="a"
                        :href="url()"
                        :target="isExternal() ? '_blank' : '_self'"
                    >
                        {{ label }}
                        <gw-icon slot="after" icon="arrow-right" />
                    </gw-button>
                </template>
            </gw-text-image>
        </template>
    </gw-section>
</template>

<script>
import RichText from '~/patterns/molecules/rich-text/rich-text';
import GwSection from '~/patterns/organisms/section/section';
import GwTextImage from '~/patterns/molecules/text-image/presets/text-image-cta.vue';
import GwCtaCard from '~/patterns/atoms/card/presets/cta-card.vue';
import LazyImg from '~/patterns/atoms/lazy-img/lazy-img';
import GwButton from '~/patterns/atoms/button/button';
import GwIcon from '~/patterns/atoms/icon/icon';

export default {
    components: {
        RichText,
        GwSection,
        GwButton,
        GwIcon,
        LazyImg,
        GwTextImage,
        GwCtaCard
    },

    props: {
        image: {
            type: Array,
            required: true,
            default: () => { return []; },
            // validator: imageObject => imageObject[0] && imageObject[0].w980
        },
        caption: {
            type: String,
            required: false,
            default: ''
        },
        heading: {
            type: String,
            required: true,
            default: ''
        },
        richText: {
            type: String,
            required: true,
            default: ''
        },
        imageAlignment: {
            type: String,
            required: false,
            default: 'left'
        },
        imageFit: {
            type: String,
            default: 'cover'
        },
        background: {
            type: String,
            required: false,
            default: 'primary'
        },
        label: {
            type: String,
            default: null
        },
        externalLink: {
            type: String,
            default: null
        },
        internalLink: {
            type: Array,
            default: () => { return []; }
        },
        asset: {
            type: Array,
            default: () => { return []; }
        },
    },

    computed: {
        hasImageOrText() {
            const hasImage = this.image && this.image[0] && this.image[0].w980;
            return hasImage || this.richText;
        },
        showButton() {
            return (this.externalLink || this.internalLink).length !== 0;
        }
    },
    methods: {
        isExternal() {
            return !this.internalLink.length;
        },
        url() {
            if (this.asset.length) {
                return this.asset[0].url;
            }

            return this.isExternal() ? this.externalLink : this.internalLink[0].url;
        }
    }
};
</script>
