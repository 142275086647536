<template>
    <div class="gw-modal-card">
        <div class="gw-modal-card__wrapper" @click="showModal = true">
            <gw-card>
                <template slot="image">
                    <slot name="image" />
                </template>

                <template slot="title">
                    <slot name="title" />
                </template>

                <slot />
            </gw-card>
        </div>
        <gw-modal
            class="gw-modal-card__modal"
            :is-visible="showModal"
            @close="showModal = false"
        >
            <template slot="close-button">
                <a
                    class="dn-modal__close"
                    @click="showModal = false"
                >
                    <gw-icon icon="cross" />
                </a>
            </template>
            <gw-card>
                <template slot="image">
                    <slot name="modal-image" />
                </template>

                <template slot="title">
                    <slot name="modal-title" />
                </template>

                <slot name="modal-content" />
            </gw-card>
        </gw-modal>
    </div>
</template>

<script>
import GwCard from '~/patterns/atoms/card/card';
import GwIcon from '~/patterns/atoms/icon/icon';
import GwModal from '~/patterns/organisms/modal/modal';

export default {
    components: {
        GwCard,
        GwIcon,
        GwModal
    },
    data() {
        return {
            showModal: false
        };
    }
};
</script>

<style lang="less" src="./modal-card.less"></style>
