<template>
    <gw-section v-if="videoUrl" width="content">
        <template v-if="heading" slot="title">{{ heading }}</template>
        <gw-video-embed :url="videoUrl" :caption="caption" />
    </gw-section>
</template>

<script>
import GwSection from '~/patterns/organisms/section/section';
import GwVideoEmbed from '~/patterns/molecules/video-embed/video-embed';

export default {
    components: {
        GwSection,
        GwVideoEmbed
    },

    props: {
        videoUrl: {
            type: String,
            default: null
        },

        caption: {
            type: String,
            default: null
        },

        heading: {
            type: String,
            required: false,
            default: null
        }
    }
};
</script>
