<template>
    <gw-section>
        <template slot="title">
            <slot name="title" />
        </template>
        <gw-button slot="button" href="/trainers" element="a" class="dn-button--label">
            <slot name="link" />
            <gw-icon slot="after" icon="arrow-right" />
        </gw-button>

        <gw-card-grid>
            <gw-card
                v-for="trainer in trainersList"
                :key="trainer.id"
                class="gw-card--trainer"
                :href="pageUrl(trainer)"
            >
                <template slot="image">
                    <lazy-img
                        v-if="trainer.image[0]"
                        class="lazy-img--cover"
                        :src="trainer.image[0].url"
                        :src-tiny="trainer.image[0].url_tiny"
                        :width="trainer.image[0].width"
                        :height="trainer.image[0].height"
                    />
                </template>
                <template slot="default">
                    <h3 class="gw-card__title">{{ trainer.title }}</h3>

                    <div class="gw-card__short-description">{{ trainer.description }}</div>

                    <div v-if="trainer.courses > 0" class="gw-card__meta">
                        {{ trainer.courses }} {{ $t('home.courses') }}
                    </div>
                </template>
            </gw-card>
        </gw-card-grid>
    </gw-section>
</template>

<script>
import GwSection from '~/patterns/organisms/section/section.vue';
import GwCardGrid from '~/patterns/molecules/card-grid/card-grid.vue';
import GwCard from '~/patterns/atoms/card/card.vue';
import GwButton from '~/patterns/atoms/button/button.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';
import LazyImg from '~/patterns/atoms/lazy-img/lazy-img.vue';

export default {
    components: {
        GwSection,
        GwCardGrid,
        GwCard,
        GwButton,
        GwIcon,
        LazyImg
    },
    props: {
        trainers: {
            type: Array,
            default: () => { return []; }
        }
    },
    computed: {
        hasTitle() {
            return !!this.$slots.title;
        },
        hasButton() {
            return !!this.$slots.button;
        },
        trainersList() {
            return this.trainers.filter(trainer => trainer.page.length);
        }
    },
    methods: {
        pageUrl(trainer) {
            if (trainer.page.length) {
                return trainer.page[0].url;
            }

            return '';
        }
    }
};
</script>
