<template>
    <validation-provider v-slot="validator" :name="field.label" :rules="rules" :custom-messages="{ 'required': 'Dit veld is verplicht' }">
        <form-field :errors="validator.errors">
            <dn-checkbox
                :id="'checkbox-'+field.value"
                v-model="check"
                :name="field.handle"
                :required="field.required"
                :validator="validator"
            >
                <!--eslint-disable-next-line vue/no-v-html-->
                <span v-html="field.label" />
            </dn-checkbox>
        </form-field>
    </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import DnCheckbox from '~/patterns/atoms/checkbox/checkbox';
import FormField from '~/patterns/molecules/form-field/form-field.vue';

export default {
    components: {
        ValidationProvider,
        DnCheckbox,
        FormField
    },
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            check: false
        };
    },
    computed: {
        rules() {
            return this.field.required ? { required: { allowFalse: false } } : null;
        }
    }
};
</script>
