<template>
    <validation-provider v-slot="validator" :name="field.label" :rules="rules" :style="`--vue-form-field-cols: ${columns}`">
        <form-field :errors="validator.errors">
            <gw-label class="form-label">{{ field.label }}</gw-label>
            <div v-if="field.instructions" class="form-field-instructions">{{ field.instructions }}</div>
            <gw-radio
                v-for="option in field.options"
                :id="'radio-'+option.value"
                :key="option.value"
                :value="option.value"
                :name="field.handle"
                class="dn-radio--group"
                @change="updateChecked"
            >
                {{ option.label }}
            </gw-radio>
        </form-field>
    </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';

import GwRadio from '~/patterns/atoms/radio/radio.vue';
import GwLabel from '~/patterns/atoms/label/label.vue';
import FormField from '~/patterns/molecules/form-field/form-field.vue';

export default {
    components: {
        ValidationProvider,
        FormField,
        GwRadio,
        GwLabel
    },
    props: {
        field: {
            type: Object,
            required: true
        },

        columns: {
            type: Number,
            required: true
        }
    },
    computed: {
        rules() {
            return this.field.required ? 'required' : null;
        }
    },
    methods: {
        updateChecked(value) {
            this.field.options.forEach((option) => {
                option.checked = option.value === value;
            });
        }
    },
};
</script>
