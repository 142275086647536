<template>
    <gw-inline-navigation-item
        :id="anchor"
        :label="label"
        class="gw-inline-navigation-item"
    >
        <template v-for="(section, key) in children">
            <component :is="map[section.__typename]" v-if="section.__typename" :key="key" v-bind="section" :data-type="section.__typename" />
        </template>
    </gw-inline-navigation-item>
</template>

<script>
import RichTextBlock from './rich-text.vue';
import ImageBlock from './image.vue';
import TextImageBlock from './text-image.vue';
import VideoBlock from '~/patterns/organisms/_flexible-section/blocktypes/video';
import CallToActionBlock from '~/patterns/organisms/_flexible-section/blocktypes/call-to-action';
import AccordionBlock from '~/patterns/organisms/_flexible-section/blocktypes/accordion';
import QuoteBlock from '~/patterns/organisms/_flexible-section/blocktypes/quote';
import EditionsScheduleBlock from '~/patterns/organisms/_flexible-section/blocktypes/editions-schedule';
import GwPageContainer from '~/patterns/molecules/page-container/page-container';
import GwInlineNavigationItem from '~/patterns/molecules/inline-navigation-item/inline-navigation-item.vue';
import TextCtaImageBlock from '~/patterns/organisms/_flexible-section/blocktypes/text-cta-image';
import Trainers from '~/patterns/organisms/_flexible-section/blocktypes/trainers';

export default {
    components: {
        GwPageContainer,
        GwInlineNavigationItem
    },
    props: {
        anchor: {
            type: String,
            required: true,
            default: () => { return ''; }
        },
        label: {
            type: String,
            required: true,
            default: () => { return ''; }
        },
        children: {
            type: Array,
            required: true,
            default: () => { return []; }
        },
    },

    data() {
        return {
            map: {
                flexibleSection_richText_BlockType: RichTextBlock,
                flexibleSection_image_BlockType: ImageBlock,
                flexibleSection_textImage_BlockType: TextImageBlock,
                flexibleSection_video_BlockType: VideoBlock,
                flexibleSection_callToAction_BlockType: CallToActionBlock,
                flexibleSection_accordion_BlockType: AccordionBlock,
                flexibleSection_quote_BlockType: QuoteBlock,
                flexibleSection_editionsSchedule_BlockType: EditionsScheduleBlock,
                flexibleSection_textCtaImage_BlockType: TextCtaImageBlock,
                flexibleSection_trainers_BlockType: Trainers,
            }
        };
    },
    computed: {
        hasFullWidthChild() {
            return this.children.length === 1 && this.children[0].width === 'page';
        }
    }
};
</script>
