<template>
    <date-picker
        v-model="date"
        :lang="locale"
        :show-week-numbers="false"
        :disabled-date="disabledBeforeToday"
        prefix-class="gw"
        :open="open"
        :class="{
            'gw-datepicker--open': open
        }"
        @pick="handleClose"
        @close="handleClose"
    >
        <template slot="input">
            <div
                class="gw-datepicker__trigger"
                @click="toggleState"
            >
                <div v-if="date">
                    {{ $d(date, 'dateShort') }}
                </div>
                <div v-else>
                    <slot name="placeholder" />
                </div>
            </div>
        </template>

        <template slot="icon-calendar">
            <gw-icon v-if="open" icon="chevron-up" />
            <gw-icon v-else icon="chevron-down" />
        </template>

        <template slot="icon-clear">
            <gw-icon icon="cross" />
        </template>
    </date-picker>
</template>

<script>
import GwIcon from '~/patterns/atoms/icon/icon.vue';
export default {
    components: {
        GwIcon
    },
    props: {
        value: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            date: null,
            locale: {
                formatLocale: {
                    firstDayOfWeek: 1,
                }
            },
            open: false
        };
    },
    watch: {
        date() {
            this.$emit('dateChanged', this.date);
        }
    },
    methods: {
        disabledBeforeToday(date) {
            const today = new Date();
            today.setHours(0, 0, 0, 0);
            return date < today;
        },
        toggleState() {
            this.open = !this.open;
        },
        handleClose() {
            this.open = false;
        }
    }
};
</script>

<style src="./datepicker.less" lang="less"></style>
