<template>
    <gw-home-template v-if="!$fetchState.pending && entry">
        <gw-hero
            slot="hero"
            :url="entry.image[0].url"
            :url-tiny="entry.image[0].url_tiny"
            :width="entry.image[0].width"
            :height="entry.image[0].height"
        >
            <template slot="title">
                <gw-page-container>
                    <gw-page-title :title="entry.title" />
                </gw-page-container>
            </template>

            <template slot="body">
                <div>
                    <p class="gw-hero__intro">{{ entry.description }}</p>
                    <div v-if="entry.entryLinks.length > 0" class="gw-hero__button-desktop">
                        <gw-button
                            class="dn-button--primary dn-button--large dn-button--min-width"
                            element="router-link"
                            :to="entry.entryLinks[0].linkEntry[0].uri"
                        >
                            {{ entry.entryLinks[0].linkTitle }}
                        </gw-button>
                    </div>
                </div>

                <div>
                    <gw-stat-list />
                </div>

                <div v-if="entry.entryLinks.length > 0" class="gw-hero__button-mobile">
                    <gw-button
                        class="dn-button--primary dn-button--large dn-button--min-width"
                        element="router-link"
                        :to="entry.entryLinks[0].linkEntry[0].uri"
                    >
                        {{ entry.entryLinks[0].linkTitle }}
                    </gw-button>
                </div>
            </template>
        </gw-hero>
        <gw-carousel
            :items="entry.programs"
        />
        <gw-section>
            <template slot="title">
                {{ $t('home.courses-link') }}
            </template>

            <gw-button
                slot="button"
                :href="localePath('/courses')"
                element="a"
                class="dn-button--label"
            >
                {{ $t('home.all-courses-link') }}
                <gw-icon slot="after" icon="arrow-right" />
            </gw-button>

            <gw-course-list :courses="entry.coursePages" />
        </gw-section>

        <flexible-section :sections="entry.flexibleSection" />
    </gw-home-template>
</template>

<script>
import GwHomeTemplate from '~/patterns/templates/home.vue';
import GwHero from '~/patterns/molecules/hero/hero.vue';
import GwButton from '~/patterns/atoms/button/button.vue';
import GwIcon from '~/patterns/atoms/icon/icon.vue';
import GwCarousel from '~/patterns/molecules/carousel/carousel.vue';
import GwCourseList from '~/patterns/organisms/course-list/course-list.vue';
import GwStatList from '~/patterns/organisms/stat-list/presets/stat-list-hero.vue';
import GwPageContainer from '~/patterns/molecules/page-container/page-container.vue';
import GwPageTitle from '~/patterns/molecules/page-title/page-title.vue';
import getHome from '~/graphql/queries/getHome.graphql';
import seo from '~/mixins/seo';
import GwSection from '~/patterns/organisms/section/section.vue';
import FlexibleSection from '~/patterns/organisms/_flexible-section/flexible-section';

export default {
    components: {
        GwHomeTemplate,
        GwPageContainer,
        GwPageTitle,
        GwButton,
        GwIcon,
        GwCarousel,
        GwCourseList,
        GwStatList,
        GwHero,
        GwSection,
        FlexibleSection,
    },
    mixins: [seo],
    layout: 'home',
    data() {
        return {
            entry: null,
            site: this.$config.site,
        };
    },
    async fetch() {
        const site = this.$config.appMultisiteHandle,
            { data } = await this.$gql.executeQuery(
                getHome,
                { site }
            );
        if (data && data.entry) {
            this.entry = data.entry;
        } else {
            this.notFound = true;
            if (process.server) {
                this.$nuxt.context.res.statusCode = 404;
            }
        }
    }
};
</script>
