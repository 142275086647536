var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.image || _vm.richText)?_c('div',{staticClass:"text-image",class:{
        'text-image--float': _vm.float,
        'text-image--grid': !_vm.float
    },attrs:{"data-orientation":_vm.imageAlignment}},[(_vm.float)?[(_vm.image)?_c('lazy-img',{staticClass:"text-image__image",attrs:{"src":_vm.image.url,"src-tiny":_vm.image.url_tiny,"height":_vm.image.height,"width":_vm.image.width,"caption":_vm.caption,"max":"768","sizes":"370px","alt":""}}):_vm._e(),_vm._v(" "),_vm._t("title"),_vm._v(" "),_c('rich-text',{staticClass:"text-image__text",attrs:{"text":_vm.richText || undefined}}),_vm._v(" "),_vm._t("button")]:[(_vm.image && _vm.imageAlignment === 'left')?_c('lazy-img',{staticClass:"text-image__image",class:{
                'lazy-img--cover': _vm.imageFit === 'cover',
                'lazy-img--contain': _vm.imageFit === 'contain'
            },attrs:{"src":_vm.image.url,"src-tiny":_vm.image.url_tiny,"height":_vm.image.height,"width":_vm.image.width,"caption":_vm.caption,"max":"768","sizes":"370px","alt":""}}):_vm._e(),_vm._v(" "),_c('div',[_vm._t("title"),_vm._v(" "),_c('rich-text',{staticClass:"text-image__text",attrs:{"text":_vm.richText || undefined}}),_vm._v(" "),_vm._t("button")],2),_vm._v(" "),(_vm.image && _vm.imageAlignment === 'right')?_c('lazy-img',{staticClass:"text-image__image",class:{
                'lazy-img--cover': _vm.imageFit === 'cover',
                'lazy-img--contain': _vm.imageFit === 'contain'
            },attrs:{"src":_vm.image.url,"src-tiny":_vm.image.url_tiny,"height":_vm.image.height,"width":_vm.image.width,"caption":_vm.caption,"max":"768","sizes":"370px","alt":""}}):_vm._e()]],2):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }