<template>
    <div id="editions-schedule">
        <gw-page-container>
            <gw-program-filters :selected-program="selectedProgram" :programs="programs" @filterChanged="selectProgram" />
        </gw-page-container>
        <gw-filters :number-of-editions="totalCount" :show-clear-filters="showClearFilters()" @clearFilters="clearFilters">
            <multiselect
                v-if="!courses.length"
                v-model="selectedCourse"
                class="multiselect--transparent"
                :options="courseOptions"
                :placeholder="$t('schedule.courses')"
                :searchable="false"
                track-by="title"
                label="title"
            >
                <template slot="placeholder">
                    {{ $t('schedule.courses') }}
                </template>
            </multiselect>

            <client-only>
                <gw-datepicker @dateChanged="updateDate">
                    <template slot="placeholder">
                        {{ $t('schedule.start-date') }}
                    </template>
                </gw-datepicker>
            </client-only>

            <gw-country-select
                :country="{ code: selectedCountry }"
                class="multiselect--transparent gw-filterselect"
                @countryChanged="updateLocation"
            >
                <template slot="placeholder">
                    {{ $t('schedule.location') }}
                </template>
            </gw-country-select>

            <multiselect
                v-model="selectedLanguage"
                class="multiselect--transparent"
                :options="languageOptions"
                :placeholder="$t('schedule.language')"
                :searchable="false"
                label="title"
            >
                <template slot="placeholder">
                    {{ $t('schedule.language') }}
                </template>
            </multiselect>

            <multiselect
                v-model="selectedAttendance"
                class="multiselect--transparent"
                :options="attendanceOptions"
                :placeholder="$t('schedule.attendance')"
                :searchable="false"
                label="title"
            >
                <template slot="placeholder">
                    {{ $t('schedule.attendance') }}
                </template>
            </multiselect>
        </gw-filters>
        <gw-page-container>
            <div class="gw-editions-schedule">
                <gw-edition
                    v-for="(edition) in editions"
                    :key="edition.id"
                    :edition="edition"
                />

                <div
                    v-if="!noEditions && !pending && showVatText"
                    class="gw-editions-schedule__vat-text"
                >
                    {{ $t('schedule.vat-text') }}
                </div>

                <gw-pagination
                    :current-count="numberOfEditions"
                    :total-count="totalCount"
                    :page-size="pageSize"
                    @getNextPage="getMoreEditions()"
                />

                <div
                    v-if="noEditions && !pending"
                    class="gw-editions-schedule__no-results"
                >
                    {{ $t('schedule.no-results') }}
                    <a @click="clearFilters()">
                        {{ $t('schedule.clear-filters') }}
                    </a>
                    {{ $t('schedule.to-show-all-options') }}
                </div>

                <div
                    v-if="pending"
                    class="gw-editions-schedule__no-results"
                >
                    <gw-loader>
                        {{ $t('schedule.loading-results') }}
                    </gw-loader>
                </div>
            </div>
        </gw-page-container>
    </div>
</template>

<script>
import GwEdition from '~/patterns/organisms/edition/edition.vue';
import getEditions from '~/graphql/queries/getEditions.graphql';
import getCategories from '~/graphql/queries/getCategories.graphql';
import getCourses from '~/graphql/queries/getCourses.graphql';
import getLocations from '~/graphql/queries/getLocations.graphql';
import GwPageContainer from '~/patterns/molecules/page-container/page-container.vue';
import GwLoader from '~/patterns/atoms/loader/loader.vue';
import GwFilters from '~/patterns/organisms/filters/filters';
import GwProgramFilters from '~/patterns/organisms/program-filters/program-filters.vue';
import Multiselect from '~/patterns/atoms/multiselect/presets/filterselect.vue';
import GwDatepicker from '~/patterns/molecules/datepicker/datepicker.vue';
import GwPagination from '~/patterns/molecules/pagination/pagination';
import GwCountrySelect from '~/patterns/atoms/multiselect/presets/countryselect';

export default {
    components: {
        GwEdition,
        GwPageContainer,
        GwLoader,
        Multiselect,
        GwDatepicker,
        GwFilters,
        GwProgramFilters,
        GwCountrySelect,
        GwPagination,
    },
    props: {
        courses: {
            type: Array,
            default: () => { return []; }
        },
        trainers: {
            type: Array,
            default: () => { return []; }
        }
    },
    data() {
        return {
            editions: [],
            programs: [],
            courseOptions: [],
            selectedCourse: null,
            selectedCountry: null,
            dateOptions: [],
            selectedDate: null,
            locationOptions: [],
            selectedLocation: null,
            languageOptions: [],
            selectedLanguage: null,
            attendanceOptions: [],
            selectedAttendance: null,
            selectedProgram: null,
            pending: false,
            filters: {
                site: this.$config.appMultisiteHandle,
            },
            page: 0,
            pageSize: 10,
            totalCount: 0,
        };
    },
    async fetch() {
        const date = new Date();
        date.setHours(0, 0, 0, 0);
        this.filters.start = '>= ' + date.toISOString();

        await Promise.all([
            this.getFilterOptions(),
            this.getCourses(),
            this.getLocations()
        ]);
    },
    computed: {
        numberOfEditions() {
            return this.editions.length;
        },
        noEditions() {
            return this.numberOfEditions === 0;
        },
        showVatText() {
            const locale = this.$config.site.locale;
            return locale === 'fr';
        }
    },
    watch: {
        selectedLanguage(payload) {
            this.filters.editionLanguage = null;
            if (payload) {
                this.filters.editionLanguage = payload.editionLanguage;
            }

            this.getEditions();
        },
        selectedCountry(payload) {
            this.filters.country = null;
            if (payload) {
                this.filters.country = payload;
            }

            this.getEditions();
        },
        selectedProgram(payload) {
            this.filters.programs = null;
            if (payload) {
                this.filters.programs = payload.id;
            }

            this.getEditions();
        },
        selectedAttendance(payload) {
            this.filters.attendance = null;
            if (payload?.attendance) {
                this.filters.attendance = payload.attendance;
            }

            this.getEditions();
        },
        selectedCourse(payload) {
            this.filters.course = null;
            if (payload) {
                this.filters.course = payload.id;
            }

            this.getEditions();
        },
        selectedDate(date) {
            this.filters.start = '>= ' + new Date().toISOString();

            if (date) {
                this.filters.start = '>= ' + date.toISOString();
            }

            this.getEditions();
        },
        selectedLocation(payload) {
            this.filters.locations = null;
            if (payload) {
                this.filters.locations = payload.id;
            }

            this.getEditions();
        }
    },
    mounted() {
        this.getEditions();
    },
    methods: {
        getEditions() {
            this.page = 0;
            this.editions = [];

            this.getMoreEditions();
        },
        async getMoreEditions() {
            this.pending = true;

            const filters = this.filters;
            if (this.courses.length) {
                filters.course = this.courses.map(course => course.id);
            }

            if (this.trainers.length) {
                filters.trainers = this.trainers.map(trainer => trainer.id);
            }

            const offset = this.page++ * this.pageSize;
            const { data } = await this.$gql.executeQuery(getEditions, { ...filters, limit: this.pageSize, offset });

            this.editions = [...this.editions, ...data.entries];
            this.totalCount = data.entryCount;

            this.$nextTick(async() => {
                // Hacky: If there are no results, ie: for a country where there will never be an edition
                if (this.editions.length === 0) {
                    const clonedFilters = { ...this.filters };

                    // Fetch editions again, but without country and with 'remote' attendance...
                    clonedFilters.country = null;
                    clonedFilters.attendance = 'remote';

                    // ...only if manual attendance filter is either 'remote' or not used
                    if (this.selectedAttendance?.attendance !== 'inPerson') {
                        const { data: editionData } = await this.$gql.executeQuery(getEditions, { ...clonedFilters, limit: this.pageSize, offset });

                        this.editions = [...this.editions, ...editionData.entries];
                        this.totalCount = editionData.entryCount;
                    }
                }

                this.pending = false;
            });
        },
        async getFilterOptions() {
            const { data } = await this.$gql.executeQuery(getCategories, { site: this.filters.site });

            this.languageOptions = data.categories.filter(item => item.groupHandle === 'languages');
            this.attendanceOptions = data.categories.filter(item => item.groupHandle === 'attendance');
            this.programs = data.categories.filter(item => item.groupHandle === 'programs');
        },
        async getLocations() {
            const { data } = await this.$gql.executeQuery(getLocations, { site: this.filters.site });

            this.locationOptions = data.entries;
        },
        async getCourses() {
            const { data } = await this.$gql.executeQuery(getCourses, { site: this.filters.site });

            this.courseOptions = data.entries;
        },
        updateLocation(option) {
            this.selectedCountry = null;

            if (option) {
                this.selectedCountry = option.code;
            }
        },
        selectProgram(payload) {
            this.selectedProgram = payload;
        },
        updateDate(date) {
            this.selectedDate = date;
        },
        clearFilters() {
            this.selectedCountry = null;
            this.selectedAttendance = null;
            this.selectedLanguage = null;
            this.selectedLocation = null;
            this.selectedDate = null;
            this.selectedCourse = null;
            this.selectedProgram = null;

            this.getEditions();
        },
        showClearFilters() {
            return [
                this.selectedCountry,
                this.selectedAttendance,
                this.selectedLanguage,
                this.selectedLocation,
                this.selectedDate,
                this.selectedCourse,
                this.selectedProgram,
            ].filter(value => value !== null).length > 0;
        },
    },
};
</script>

<style src="./editions-schedule.less" lang="less"></style>
