<template>
    <gw-backdrop backdrop-type="home">
        <div class="gw-carousel">
            <drag-to-scroll class="gw-carousel__wrapper">
                <span class="gw-carousel__padding" />
                <gw-program-card
                    v-for="(item, index) in items"
                    :key="index"
                    :program="item"
                    class="gw-carousel__item"
                />
                <span class="gw-carousel__padding" />
            </drag-to-scroll>
        </div>
    </gw-backdrop>
</template>

<script>
import GwProgramCard from '~/patterns/atoms/card/presets/program-card.vue';
import DragToScroll from '~/patterns/atoms/drag-to-scroll/drag-to-scroll.vue';
import GwBackdrop from '~/patterns/atoms/backdrop/backdrop.vue';

export default {
    components: {
        GwProgramCard,
        DragToScroll,
        GwBackdrop
    },
    props: {
        items: {
            type: Array,
            default: () => { return []; }
        }
    }
};
</script>

<style src="./carousel.less" lang="less"></style>
