<template>
    <gw-section width="content">
        <template v-if="heading" slot="title">{{ heading }}</template>
        <gw-accordion :items="children" />
    </gw-section>
</template>

<script>
import GwSection from '~/patterns/organisms/section/section.vue';
import GwAccordion from '~/patterns/atoms/accordion/accordion.vue';

export default {
    components: {
        GwSection,
        GwAccordion,
    },

    props: {
        children: {
            type: Array,
            required: true,
            default: () => { return []; }
        },
        heading: {
            type: String,
            required: false,
            default: null
        }
    }
};
</script>
