<template>
    <gw-section>
        <template v-if="heading" slot="title">{{ heading }}</template>

        <gw-card-grid>
            <template v-if="cards.length">
                <gw-modal-card v-for="(card, index) in cards" :key="index">
                    <template v-if="card.image && card.image.length" slot="image">
                        <gw-lazy-img
                            class="lazy-img--cover"
                            :src="card.image[0].url"
                            :src-tiny="card.image[0].url_tiny"
                            :width="card.image[0].width"
                            :height="card.image[0].height"
                        />
                    </template>

                    <template slot="title">
                        {{ card.title }}
                    </template>

                    <div class="gw-card__meta">{{ card.extraInfo }}</div>
                    <div class="gw-card__short-description">{{ card.description }}</div>
                    <div class="gw-card__meta">{{ $t('event.read-more') }}</div>

                    <template v-if="card.image && card.image.length" slot="modal-image">
                        <gw-lazy-img
                            class="lazy-img--cover"
                            :src="card.image[0].url"
                            :src-tiny="card.image[0].url_tiny"
                            :width="card.image[0].width"
                            :height="card.image[0].height"
                        />
                    </template>
                    <template slot="modal-title">
                        {{ card.title }}
                    </template>
                    <template slot="modal-content">
                        <div class="gw-card__meta">{{ card.extraInfo }}</div>
                        {{ card.linkedIn }}
                        <!-- eslint-disable-next-line vue/no-v-html -->
                        <div v-if="card.richText" v-html="card.richText" />
                        <div v-else>
                            {{ card.description }}
                        </div>
                    </template>
                </gw-modal-card>
            </template>
        </gw-card-grid>
    </gw-section>
</template>

<script>
import GwSection from '~/patterns/organisms/section/section.vue';
import GwCardGrid from '~/patterns/molecules/card-grid/card-grid';
import GwModalCard from '~/patterns/organisms/modal-card/modal-card';

import GwLazyImg from '~/patterns/atoms/lazy-img/lazy-img.vue';

export default {
    components: {
        GwSection,
        GwCardGrid,
        GwModalCard,
        GwLazyImg
    },
    props: {
        heading: {
            type: String,
            required: false,
            default: null
        },
        cards: {
            type: Array,
            required: false,
            default: () => { return []; },
        }
    },
    data() {
        return {
            showModal: false
        };
    }
};
</script>
