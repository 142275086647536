<template>
    <div
        :style="`--vue-form-field-cols: ${columns}`"
    >
        <!--eslint-disable-next-line vue/no-v-html-->
        <div v-html="field.value" />
    </div>
</template>

<script>
export default {
    props: {
        field: {
            type: Object,
            required: true
        },
        columns: {
            type: Number,
            required: true
        }
    }
};
</script>
