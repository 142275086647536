<template>
    <div class="gw-edition">
        <div class="gw-edition__date">
            {{ date }}
        </div>
        <div class="gw-edition__card gw-card--edition">
            <div class="gw-edition__main">
                <div
                    class="gw-edition__row"
                    @click="informationExpanded = !informationExpanded"
                >
                    <h3 class="gw-edition__title gw-card__title" :title="edition.title">
                        {{ edition.title }}
                    </h3>

                    <div class="gw-edition__price hidden-desktop">
                        {{ price }}
                    </div>
                </div>

                <div
                    v-if="edition.extraInfo"
                    class="gw-edition__message hidden-desktop"
                    @click="informationExpanded = !informationExpanded"
                >
                    {{ edition.extraInfo }}
                </div>

                <div
                    class="gw-edition__meta"
                    @click="informationExpanded = !informationExpanded"
                >
                    <gw-category
                        v-if="edition.programs[0]"
                        class="gw-category--baseline"
                        :data-category="edition.programs[0].slug"
                    >
                        <div v-if="informationExpanded" class="gw-edition__subtitle">
                            {{ edition.programs[0].title }}
                        </div>
                        <template v-else>
                            {{ edition.programs[0].title }}
                        </template>
                    </gw-category>

                    <div class="gw-edition__seperator hidden-desktop">
                        &bullet;
                    </div>

                    <div class="gw-edition__spec hidden-desktop">
                        {{ edition.editionLanguage }}
                    </div>

                    <div class="gw-edition__seperator hidden-desktop">
                        &bullet;
                    </div>

                    <div class="gw-edition__spec hidden-desktop">
                        {{ edition.locations[0].title }}
                    </div>

                    <div v-if="edition.extraInfo" class="gw-edition__spec gw-edition__message hidden-mobile">
                        {{ edition.extraInfo }}
                    </div>
                </div>

                <div v-if="informationExpanded">
                    <div v-if="edition.course[0] && edition.course[0].description" class="gw-edition__description">
                        {{ edition.course[0].description }}
                    </div>

                    <a v-if="!isCoursePage" class="gw-edition__link" @click="openCourse()">
                        {{ $t('edition.more-info') }}
                    </a>

                    <div
                        v-if="edition.trainers.length > 0"
                        class="gw-edition__section"
                    >
                        <div class="gw-edition__subtitle">
                            {{ $t('edition.trainers.title') }}
                        </div>

                        <div class="gw-edition__trainers">
                            <template
                                v-for="(trainer, index) in edition.trainers"
                            >
                                <gw-avatar
                                    :key="`trainer-avatar-${index}`"
                                    class="gw-avatar--sm"
                                    :image="trainer.image[0]"
                                    :alt="trainer.title"
                                />
                                <template v-if="trainer.page[0] && trainer.page[0].uri">
                                    <nuxt-link
                                        :key="`trainer-name-${index}`"
                                        class="gw-edition__link hidden-desktop"
                                        :to="`/${trainer.page[0].uri}`"
                                    >
                                        {{ trainer.title }}
                                    </nuxt-link>
                                </template>
                                <div
                                    v-else
                                    :key="`trainer-name-${index}`"
                                    class="hidden-desktop"
                                >
                                    {{ trainer.title }}
                                </div>
                            </template>
                            <div
                                v-for="(trainer, index) in edition.trainers"
                                :key="index"
                                class="hidden-mobile"
                            >
                                <template v-if="index !== 0 ">
                                    <span v-if="index === edition.trainers.length - 1">&nbsp;{{ $t('edition.conjunction') }}</span>
                                    <span v-else>,</span>
                                </template>

                                <template v-if="trainer.page[0] && trainer.page[0].uri">
                                    <nuxt-link
                                        :key="`trainer-name-${index}`"
                                        class="gw-edition__link"
                                        :to="`/${trainer.page[0].uri}`"
                                    >
                                        {{ trainer.title }}
                                    </nuxt-link>
                                </template>
                                <span v-else :key="`trainer-name-${index}`">
                                    {{ trainer.title }}
                                </span>
                            </div>
                        </div>
                    </div>

                    <div class="hidden-mobile">
                        <gw-button
                            v-if="edition.course[0]"
                            class="dn-button--large dn-button--download gw-edition__button"
                            element="router-link"
                            :to="getBrochureLink(edition)"
                            data-ga-category="download"
                            data-ga-action="download-brochure"
                            :data-ga-label="edition.id"
                        >
                            {{ $t('edition.download-brochure') }}
                            <gw-icon slot="after" icon="arrow-download" />
                        </gw-button>

                        <gw-button
                            class="dn-button--large gw-edition__button"
                            element="router-link"
                            :to="`/checkout?id=${edition.id}`"
                            data-ga-category="enroll"
                            data-ga-action="enroll-schedule"
                            :data-ga-label="edition.id"
                        >
                            {{ $t('schedule.enroll') }}
                            <gw-icon slot="after" icon="arrow-right" />
                        </gw-button>
                    </div>
                </div>
            </div>

            <div
                class="gw-edition__aside gw-edition__cell gw-edition__specs"
                @click="informationExpanded = !informationExpanded"
            >
                <div class="gw-edition__indicator hidden-mobile">
                    <template v-if="informationExpanded">
                        <gw-icon slot="after" icon="chevron-up" />
                    </template>
                    <template v-else>
                        <gw-icon slot="after" icon="chevron-down" />
                    </template>
                </div>

                <div class="hidden-mobile">
                    <div class="gw-edition__price">
                        {{ price }}
                    </div>

                    <div class="gw-edition__spec">
                        {{ edition.editionLanguage }}
                    </div>

                    <div class="gw-edition__spec">
                        {{ edition.locations[0].title }}
                    </div>
                </div>

                <template v-if="informationExpanded">
                    <div v-if="edition.sessions.length > 0" class="gw-edition__section">
                        <div class="gw-edition__subtitle">
                            {{ $t('edition.training-dates.title') }}
                        </div>
                        <div class="gw-edition__sessions">
                            <template v-for="(session, index) in edition.sessions">
                                <div :key="index">
                                    {{ $d(new Date(session.start), 'date') }},
                                </div>
                                <div :key="index">
                                    {{ $d(removeTimezoneOffset(session.start), 'time') }}
                                    &ndash;
                                    {{ $d(removeTimezoneOffset(session.end), 'time') }}
                                </div>
                            </template>
                        </div>
                    </div>

                    <div v-if="edition.course && edition.course.length > 0" class="gw-edition__section">
                        <div class="gw-edition__subtitle">
                            <template v-if="edition.course[0].list[0] && edition.course[0].list[0].heading !== null">
                                {{ edition.course[0].list[0].heading }}
                            </template>
                            <template v-else>
                                {{ $t('edition.usp.title') }}
                            </template>
                        </div>

                        <ul class="gw-edition__usp-list">
                            <template v-if="edition.course[0].list[0] && edition.course[0].list[0].children && edition.course[0].list[0].children.length > 0">
                                <li v-for="(listItem, index) in edition.course[0].list[0].children" :key="`list-item-${index}`">
                                    {{ listItem.plainText }}
                                </li>
                            </template>

                            <template v-else>
                                <li>{{ $t('edition.usp.first') }}</li>
                                <li>{{ $t('edition.usp.second') }}</li>
                                <li>{{ $t('edition.usp.third') }}</li>
                            </template>
                        </ul>
                    </div>

                    <div class="hidden-desktop">
                        <gw-button
                            v-if="edition.course[0]"
                            class="dn-button--large dn-button--download gw-edition__button"
                            element="router-link"
                            :to="getBrochureLink(edition)"
                            target="_blank"
                        >
                            {{ $t('edition.download-brochure') }}
                            <gw-icon slot="after" icon="arrow-download" />
                        </gw-button>

                        <gw-button
                            class="dn-button--large gw-edition__button"
                            element="router-link"
                            :to="`/checkout?id=${edition.id}`"
                            data-ga-category="enroll"
                            data-ga-action="enroll-schedule"
                            :data-ga-label="edition.id"
                        >
                            {{ $t('schedule.enroll') }}
                            <gw-icon slot="after" icon="arrow-right" />
                        </gw-button>
                    </div>
                </template>
                <div class="hidden-desktop">
                    <div
                        v-if="informationExpanded"
                        class="gw-edition__read-more"
                    >
                        {{ $t('edition.show-less') }}
                        <gw-icon slot="after" icon="chevron-up" />
                    </div>
                    <div
                        v-else
                        class="gw-edition__read-more"
                    >
                        {{ $t('edition.show-more') }}
                        <gw-icon slot="after" icon="chevron-down" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import GwIcon from '~/patterns/atoms/icon/icon.vue';
import GwButton from '~/patterns/atoms/button/button';
import GwCategory from '~/patterns/atoms/category/category';
import GwAvatar from '~/patterns/atoms/avatar/avatar';
import getCoursePageSlug from '~/graphql/queries/getCoursePageSlug.graphql';

export default {
    components: {
        GwIcon,
        GwButton,
        GwCategory,
        GwAvatar
    },
    props: {
        edition: {
            type: Object,
            default: () => { return {}; }
        },
    },
    data() {
        return {
            currency: localStorage.getItem('selectedCurrency'),
            informationExpanded: false
        };
    },
    computed: {
        date() {
            return this.$d(new Date(this.edition.start), 'dateShorter') || null;
        },
        price() {
            return this.addCurrency(this.edition.prices);
        },
        isCoursePage() {
            return this.$route.name.includes('courses-category-slug');
        }
    },
    mounted() {
        this.$nuxt.$on('selectedCurrency', () => {
            this.currency = localStorage.getItem('selectedCurrency');
        });
    },
    methods: {
        async openCourse() {
            const { data } = await this.$gql.executeQuery(getCoursePageSlug, {
                site: this.$config.appMultisiteHandle,
                course: this.edition.course[0].id
            });

            const programSlug = this.edition.programs[0].slug;

            await this.$router.push(`${this.localePath('/courses')}/${programSlug}/${data.entry.slug}`);
        },
        getPrice(prices) {
            return prices.filter(price => price.currency === this.currency)[0];
        },
        addCurrency(prices) {
            const price = this.getPrice(prices);

            const locale = this.$config.site.locale;
            const vatIncluded = false;
            const showVatText = locale === 'fr' && !vatIncluded;
            const exVatText = this.$t('schedule.ex-vat');

            function vat() {
                if (showVatText) {
                    return ` ${exVatText}`;
                } else {
                    return '';
                }
            }

            if (price.price !== undefined) {
                return `${this.$n(price.price, {
                    style: 'currency',
                    currency: price.currency,
                    maximumFractionDigits: 0
                })}${vat()}`;
            }
        },
        getBrochureLink(edition) {
            const path = '/form/brochure';
            const courseId = edition.course[0]?.id;
            const editionCode = edition.editionCode;
            const query = `?course-id=${courseId}&edition=${editionCode}`;

            return path + query;
        },
        removeTimezoneOffset(date) {
            const parsedDate = new Date(date);
            const userTimezoneOffset = parsedDate.getTimezoneOffset() * 60000;

            return new Date(parsedDate.getTime() + userTimezoneOffset);
        }
    }
};
</script>

<style src="./edition.less" lang="less"></style>
