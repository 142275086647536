<template>
    <validation-provider v-slot="validator" :name="field.label" :rules="rules">
        <form-field :errors="validator.errors" :name="field.handle">
            <dn-checkbox
                v-for="(option) in field.options"
                :id="'checkbox-'+option.value"
                :key="option.value"
                v-model="option.checked"
                :name="field.handle"
                :required="field.required"
                :validator="validator"
            >
                {{ option.label }}
            </dn-checkbox>
        </form-field>
    </validation-provider>
</template>

<script>
import { ValidationProvider } from 'vee-validate';
import DnCheckbox from '~/patterns/atoms/checkbox/checkbox';
import FormField from '~/patterns/molecules/form-field/form-field.vue';

export default {
    components: {
        ValidationProvider,
        DnCheckbox,
        FormField
    },
    props: {
        field: {
            type: Object,
            required: true
        }
    },
    computed: {
        rules() {
            return this.field.required ? 'required' : null;
        }
    }
};
</script>
