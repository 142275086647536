<template>
    <gw-section v-if="hasImage" :width="width" background="none">
        <template v-if="heading" slot="title">{{ heading }}</template>
        <lazy-img
            v-if="image[0]"
            :class="{
                'lazy-img--cover': width === 'page',
                'lazy-img--half-height': width === 'page'
            }"
            :src="image[0].url"
            :src_tiny="image[0].url_tiny"
            :height="image[0].height"
            :width="image[0].width"
            :caption="caption"
            max="768"
            sizes="370px"
            alt=""
        />
    </gw-section>
</template>

<script>
import GwSection from '~/patterns/organisms/section/section.vue';
import LazyImg from '~/patterns/atoms/lazy-img/lazy-img';

export default {
    components: {
        GwSection,
        LazyImg
    },

    props: {
        image: {
            type: Array,
            required: true,
            default: () => { return []; },
            // validator: imageObject => imageObject[0] && imageObject[0].w1260
        },

        caption: {
            type: String,
            required: false,
            default: ''
        },

        heading: {
            type: String,
            required: false,
            default: null
        },

        width: {
            type: String,
            required: false,
            default: 'content'
        }
    },

    computed: {
        hasImage() {
            return this.image && this.image[0];
        }
    }
};
</script>
