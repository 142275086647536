<template>
    <div class="flexible-section">
        <template v-for="(section, key) in sections">
            <component :is="map[section.__typename]" v-if="section.__typename" :key="key" v-bind="section" />
        </template>
    </div>
</template>

<script>
import RichTextBlock from './blocktypes/rich-text.vue';
import ImageBlock from './blocktypes/image.vue';
import TextImageBlock from './blocktypes/text-image.vue';
import CallToActionBlock from './blocktypes/call-to-action.vue';
import VideoBlock from './blocktypes/video.vue';
import AccordionBlock from './blocktypes/accordion.vue';
import QuoteBlock from './blocktypes/quote.vue';
import InlineNavigationBlock from './blocktypes/inline-navigation';
import EditionsScheduleBlock from './blocktypes/editions-schedule';
import TextCtaImageBlock from './blocktypes/text-cta-image';
import Trainers from './blocktypes/trainers';
import FormBlock from './blocktypes/form';
import CardsBlock from './blocktypes/cards';
import HtmlEmbed from './blocktypes/html-embed';

export default {
    props: {
        sections: {
            type: Array,
            required: true,
            default: () => { return []; }
        }
    },

    data() {
        return {
            map: {
                flexibleSection_richText_BlockType: RichTextBlock,
                flexibleSection_image_BlockType: ImageBlock,
                flexibleSection_textImage_BlockType: TextImageBlock,
                flexibleSection_video_BlockType: VideoBlock,
                flexibleSection_callToAction_BlockType: CallToActionBlock,
                flexibleSection_accordion_BlockType: AccordionBlock,
                flexibleSection_quote_BlockType: QuoteBlock,
                flexibleSection_inlineNavigation_BlockType: InlineNavigationBlock,
                flexibleSection_editionsSchedule_BlockType: EditionsScheduleBlock,
                flexibleSection_textCtaImage_BlockType: TextCtaImageBlock,
                flexibleSection_trainers_BlockType: Trainers,
                flexibleSection_form_BlockType: FormBlock,
                flexibleSection_cards_BlockType: CardsBlock,
                flexibleSection_htmlEmbed_BlockType: HtmlEmbed,
            }
        };
    }
};
</script>
