<template>
    <div class="gw-hero">
        <gw-lazy-img
            class="gw-hero__image lazy-img--cover"
            :src="url"
            :src-tiny="urlTiny"
            :width="width"
            :height="height"
        />

        <div class="gw-hero__overlay" />
        <div class="gw-hero__header-spacer" />
        <div class="gw-hero__wrapper">
            <div v-if="!!$slots.title" class="gw-hero__title">
                <slot name="title" />
            </div>

            <gw-page-container
                v-if="!!$slots.body"
                class="gw-hero__body"
                data-layout="2-columns"
            >
                <slot name="body" />
            </gw-page-container>
        </div>
    </div>
</template>

<script>
import GwLazyImg from '~/patterns/atoms/lazy-img/lazy-img.vue';
import GwPageContainer from '~/patterns/molecules/page-container/page-container.vue';

export default {
    components: {
        GwLazyImg,
        GwPageContainer
    },
    props: {
        url: {
            type: String,
            default: null
        },
        urlTiny: {
            type: String,
            default: null
        },
        width: {
            type: Number,
            default: null
        },
        height: {
            type: Number,
            default: null,
        }
    }
};
</script>

<style lang="less" src="./hero.less" />
