<template>
    <div class="gw-loader">
        <gw-icon icon="loader" />
        <slot />
    </div>
</template>

<script>
import GwIcon from '~/patterns/atoms/icon/icon.vue';
export default {
    components: {
        GwIcon
    }
};
</script>

<style src="./loader.less" lang="less"></style>
