<template>
    <gw-section v-if="hasImageOrText" width="content">
        <template v-if="heading" slot="title">{{ heading }}</template>
        <text-image
            :image="image[0]"
            :caption="caption || undefined"
            :rich-text="richText || undefined"
            :image-alignment="imageAlignment || undefined"
        />
    </gw-section>
</template>

<script>
import GwSection from '~/patterns/organisms/section/section.vue';
import TextImage from '~/patterns/molecules/text-image/text-image.vue';

export default {
    components: {
        TextImage,
        GwSection
    },

    props: {
        image: {
            type: Array,
            required: true,
            default: () => { return []; },
            // validator: imageObject => imageObject[0] && imageObject[0].w980
        },
        caption: {
            type: String,
            required: false,
            default: ''
        },
        heading: {
            type: String,
            required: false,
            default: ''
        },
        richText: {
            type: String,
            required: true,
            default: ''
        },
        imageAlignment: {
            type: String,
            required: false,
            default: 'left'
        }
    },

    computed: {
        hasImageOrText() {
            const hasImage = this.image && this.image[0] && this.image[0].w980;
            return hasImage || this.richText;
        }
    }
};
</script>
