<template>
    <intersection-anchor class="intersection-anchor--header" intersection-class="scrolled-header" />
</template>

<script>
import intersectionAnchor from '~/patterns/atoms/intersection-anchor/intersection-anchor.vue';

export default {
    components: {
        intersectionAnchor
    },
};
</script>

<style src="./header.less" lang="less"></style>
