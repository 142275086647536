<template>
    <div class="gw-inline-navigation-item">
        <div class="gw-inline-navigation-item__label">
            <gw-page-container class="gw-page-container--content">
                <h4>{{ label }}</h4>
            </gw-page-container>
        </div>

        <slot />
    </div>
</template>

<script>
import GwPageContainer from '~/patterns/molecules/page-container/page-container.vue';
export default {
    components: {
        GwPageContainer
    },
    props: {
        label: {
            type: String,
            default: ''
        }
    }
};
</script>

<style src="./inline-navigation-item.less" lang="less"></style>
