<template>
    <div v-if="form">
        <validation-observer v-slot="{ handleSubmit }">
            <form
                v-if="!isSubmitted"
                ref="form"
                class="vue-form"
                :name="form.handle"
                @submit.prevent="handleSubmit(submitForm)"
            >
                <div v-if="form.description" class="vue-form__description">{{ form.description }}</div>

                <div
                    v-for="(page, index) in pages"
                    :key="index"
                    class="vue-form__section"
                >
                    <h3 class="vue-form__title">{{ page.label }}</h3>

                    <form-fields :rows="page.rows" :loading="isLoading">
                        <template #errors>
                            <div v-for="error in errors" :key="error" class="vue-form-fields__error">{{ error }}</div>
                        </template>
                    </form-fields>
                </div>
                <div v-for="message in successMessages" :key="message" class="vue-form-fields__message">{{ message }}</div>
            </form>
        </validation-observer>
    </div>
</template>
<script>

import { get, flatMap } from 'lodash';
import { ValidationObserver } from 'vee-validate';

import getForm from '~/graphql/queries/freeform.graphql';

import FormFields from '~/patterns/molecules/vue-form-fields/vue-form-fields.vue';

export default {
    components: {
        FormFields,
        ValidationObserver
    },
    props: {
        formHandle: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            form: {},
            isSubmitted: false,
            hasErrors: false,
            formData: {},
            errors: [],
            successMessages: [],
            isLoading: false
        };
    },
    async fetch() {
        const handle = this.formHandle.toLowerCase(),
            site = 'en',
            { data } = await this.$gql.executeQuery(
                getForm,
                { site, handle }
            );

        this.form = data.freeform.form;

        const formDataResponse = await this.$axios.get(
            '/actions/gladwell/form/view?id=' + this.form.id,
            {
                baseURL: this.getCmsBaseUrl(), // overwrite baseURL to make sure we don't sent to CMS!
            }
        );

        this.formData['form-name'] = this.form.handle;
        this.formData.freeform_payload = formDataResponse.data.freeform_payload;
        this.formData.formHash = formDataResponse.data.hash;
        this.formData[formDataResponse.data.honeypot.name] = formDataResponse.data.honeypot.value;
        this.formData.action = 'freeform/submit';
        this.formData.method = 'POST';
        this.formData.ajax = true;
        this.formData.disableSubmit = true;
        this.formData.disableReset = true;
    },
    computed: {
        pages() {
            return get(this, 'form.pages', []);
        },
        rows() {
            return flatMap(this.pages, page => page.rows || []);
        },
    },
    methods: {
        getCmsBaseUrl() {
            return this.$config.cmsUrl.replace('/graphql', '');
        },
        getFormData() {
            return new FormData(this.$refs.form);
        },
        addTokensToFormData(formData) {
            for (const key in this.formData) {
                if (key) {
                    formData.append(key, this.formData[key]);
                }
            }
            return formData;
        },
        async submitForm() {
            try {
                if (this.isLoading) {
                    return;
                }
                this.isLoading = true;
                const formData = this.addTokensToFormData(this.getFormData());

                const response = await this.sendToCraft(formData);

                if (response.status === 200 && this.form.returnUrl) {
                    this.$router.push(this.form.returnUrl);
                } else if (response.status === 200) {
                    this.successMessages = ['We hebben je bericht ontvangen'];
                    this.$refs.form.reset();
                } else {
                    this.errors = ['Er ging iets mis. Probeer het opnieuw'];
                }
                this.isLoading = false;
            } catch (e) {
                this.errors = [e.message];
                this.isLoading = false;
            }
        },
        sendToCraft(formData) {
            return this.$axios.post(
                'actions/gladwell/submit',
                new URLSearchParams(formData).toString(),
                {
                    baseURL: this.getCmsBaseUrl(), // overwrite baseURL to make sure we don't sent to CMS!
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    }
                }
            );
        }
    }
};
</script>

<style lang="less" src="./vue-form.less" />
