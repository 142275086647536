<template>
    <gw-stat-list class="gw-stat-list--hero">
        <gw-stat-slab class="gw-stat-list__item gw-stat-slab--hero" label-position="bottom">
            <template slot="label">
                {{ $t('stat-list.succesful-alumni') }}
            </template>
            <template slot="data">
                50K
            </template>
        </gw-stat-slab>

        <gw-stat-slab class="gw-stat-list__item gw-stat-slab--hero" label-position="bottom">
            <template slot="label">
                {{ $t('stat-list.market-leader') }}
            </template>
            <template slot="data">
                <img
                    src="/logos/SAFe-partner-logo.png"
                    srcset="/logos/SAFe-partner-logo.png 1x, /logos/SAFe-partner-logo@2x.png 2x"
                    alt="SAFE Logo"
                >
            </template>
        </gw-stat-slab>

        <gw-stat-slab class="gw-stat-list__item gw-stat-slab--hero" label-position="bottom">
            <template slot="label">
                {{ $t('stat-list.certified-trainers') }}
            </template>
            <template slot="data">
                72
            </template>
        </gw-stat-slab>
    </gw-stat-list>
</template>

<script>
import GwStatList from '~/patterns/organisms/stat-list/stat-list.vue';
import GwStatSlab from '~/patterns/atoms/stat-slab/stat-slab.vue';

export default {
    components: {
        GwStatList,
        GwStatSlab
    }
};
</script>
