<template>
    <div style="visibility: hidden; pointer-events: none; width: 0; height: 0;">
        <input
            v-model="value"
            type="text"
            :name="field.handle"
        >
    </div>
</template>

<script>

export default {
    components: {},
    props: {
        field: {
            type: Object,
            required: true
        },
    },
    data() {
        return {
            value: ''
        };
    },
    mounted() {
        this.value = this.field.value;

        if (this.$route.query[this.field.handle]) {
            this.value = this.$route.query[this.field.handle];
        }
    }
};
</script>
