<template>
    <div v-if="cookiesAccepted && (service === SERVICE_YOUTUBE || SERVICE_VIMEO)" class="video-embed">
        <div class="video-embed__inner">
            <iframe
                v-if="service === SERVICE_YOUTUBE"
                class="embed-responsive-item"
                :src="`https://www.youtube-nocookie.com/embed/${id}?modestbranding=1&controls=0&rel=0`"
                frameborder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowfullscreen
                title="Video"
                :loading="loading"
            />
            <iframe
                v-else-if="service === SERVICE_VIMEO"
                class="embed-responsive-item"
                :src="`https://player.vimeo.com/video/${id}?title=0&byline=0&portrait=0&color=f7f5f1&dnt=1`"
                frameborder="0"
                allow="autoplay; fullscreen"
                allowfullscreen
                title="Video"
                :loading="loading"
            />
        </div>
        <p v-if="caption" class="video-embed__caption">{{ caption }}</p>
    </div>
    <div v-else class="video-embed__blocked">
        <span>We need your consent to show you this video</span>
        <sf-button class="dn-button--secondary" @click="acceptCookies">Accept cookies</sf-button>
    </div>
</template>

<script>

import SfButton from '~/patterns/atoms/button/button.vue';

const SERVICE_YOUTUBE = 'youtube',
    SERVICE_VIMEO = 'vimeo',
    YTREGEX = /^(?:(?:https?:)?\/\/)?(?:(?:www|m)\.)?(?:youtube\.com|youtu.be)(?:\/(?:[\w]+\?v=|embed\/|v\/)?)([\w-]+)(?:\S+)?$/i,
    VIMEOREGEX = /^https:\/\/vimeo\.com\/([0-9]+)$/i;

export default {
    components: {
        SfButton
    },
    props: {
        url: {
            type: String,
            required: true,
            default: ''
        },
        caption: {
            type: String,
            required: false,
            default: ''
        },
        loading: {
            type: String,
            default: 'lazy'
        }
    },
    data() {
        return {
            SERVICE_VIMEO,
            SERVICE_YOUTUBE
        };
    },
    computed: {
        service() {
            return this.parsed.service;
        },
        id() {
            return this.parsed.id;
        },
        parsed() {
            return this.parseUrl(this.url);
        },
        cookiesAccepted() {
            // @TODO: connect this to the actual cookiebar
            return true;
            // return this.$cookies.get('cookie_accept_all');
        }
    },

    methods: {
        parseUrl(url) {
            const service = this.parseService(url);

            return {
                service,
                id: this.parseId(service, this.url)
            };
        },
        parseService(url) {
            if (url.match(YTREGEX)) {
                return SERVICE_YOUTUBE;
            }

            if (url.match(VIMEOREGEX)) {
                return SERVICE_VIMEO;
            }

            return null;
        },
        parseId(service, url) {
            switch (service) {
            case SERVICE_YOUTUBE: {
                const matches = url.match(YTREGEX);
                return matches[1];
            }
            case SERVICE_VIMEO: {
                const matches = url.match(VIMEOREGEX);
                return matches[1];
            }
            }

            return null;
        },
        acceptCookies() {
            const options = {
                    path: '/',
                    maxAge: 60 * 60 * 24 * 365
                },
                cookieList = [
                    { name: 'cookie_accept_all', value: '1', opts: options },
                    { name: 'cookiebar_dismissed', value: '1', opts: options }
                ];
            this.$cookies.setAll(cookieList);
            this.$router.go();
        }
    }
};

</script>

<style lang="less" src="./video-embed.less"></style>
