<template>
    <div class="gw-course-list">
        <div class="gw-course-list__wrapper">
            <nuxt-link
                v-for="(course, index) in courses"
                :key="index"
                class="gw-course-list__item"
                :to="`/${course.uri}`"
            >
                <gw-icon class="gw-course-list__icon" icon="arrow-right" />
                {{ course.title }}
            </nuxt-link>
        </div>
    </div>
</template>

<script>
import GwIcon from '~/patterns/atoms/icon/icon.vue';

export default {
    components: {
        GwIcon
    },
    props: {
        courses: {
            type: Array,
            default: () => { return []; }
        }
    },
};
</script>

<style src="./course-list.less" lang="less"></style>
