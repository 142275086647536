<template>
    <gw-section
        v-if="htmlEmbed"
        width="content"
        background="none"
    >
        <!--eslint-disable vue/no-v-html-->
        <div v-html="htmlEmbed" />
        <!--eslint-enable vue/no-v-html-->
    </gw-section>
</template>

<script>
import GwSection from '~/patterns/organisms/section/section.vue';

export default {
    components: {
        GwSection
    },

    props: {
        htmlEmbed: {
            type: String,
            required: true,
            default: null
        }
    }
};
</script>
