<template>
    <div class="gw-filters">
        <div class="gw-filters__title gw-filters__title--desktop">
            {{ $t('schedule.filter') }}
            {{ numberOfEditions }}
            <span v-if="numberOfEditions === 1">
                {{ $t('schedule.result' ) }}
            </span>
            <span v-else>
                {{ $t('schedule.results' ) }}
            </span>

            <a
                v-if="showClearFilters"
                class="gw-filters__reset hidden-mobile"
                @click="clearFilters()"
            >
                {{ $t('schedule.clear-filters') }}
            </a>
        </div>

        <gw-expander
            class="gw-expander--filters"
            icon-maximize="plus"
            icon-minimize="minus"
        >
            <div slot="trigger_toggle" class="gw-filters__title gw-filters__title--mobile">
                {{ $t('schedule.filter') }}
                {{ numberOfEditions }}
                <span v-if="numberOfEditions === 1">
                    {{ $t('schedule.result' ) }}
                </span>
                <span v-else>
                    {{ $t('schedule.results' ) }}
                </span>
            </div>

            <template slot="content">
                <div class="gw-filters__wrapper">
                    <a
                        v-if="showClearFilters"
                        class="gw-filters__reset hidden-desktop"
                        @click="clearFilters()"
                    >
                        {{ $t('schedule.clear-filters') }}
                    </a>

                    <slot />
                </div>
            </template>
        </gw-expander>
    </div>
</template>

<script>
import GwExpander from '~/patterns/molecules/expander/expander.vue';
export default {
    components: {
        GwExpander,
    },
    props: {
        numberOfEditions: {
            type: Number,
            default: 0
        },
        showClearFilters: {
            type: Boolean,
            default: false,
        }
    },
    methods: {
        clearFilters() {
            this.$emit('clearFilters');
        }
    }
};
</script>

<style src="./filters.less" lang="less"></style>
