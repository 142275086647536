<template>
    <gw-backdrop>
        <gw-page-container class="gw-page-container--content">
            <gw-quote
                v-view="viewHandler"
                class="gw-pullquote"
            >
                <div class="gw-pullquote__text">
                    <slot />
                </div>
            </gw-quote>

            <div v-if="!!$slots.author" class="gw-pullquote__author">
                <slot name="author" />
            </div>
        </gw-page-container>
    </gw-backdrop>
</template>

<script>
import GwQuote from '~/patterns/atoms/quote/quote.vue';
import GwBackdrop from '~/patterns/atoms/backdrop/backdrop.vue';
import GwPageContainer from '~/patterns/molecules/page-container/page-container.vue';

export default {
    components: {
        GwQuote,
        GwBackdrop,
        GwPageContainer
    },

    data() {
        return {
            visiblePercentage: 0
        };
    },

    methods: {
        viewHandler(event) {
            this.visiblePercentage = Math.round(event.percentCenter * 100);
        }
    }
};
</script>

<style lang="less" src="./pullquote.less"></style>
