<template>
    <dn-article v-if="text">
        <!--eslint-disable vue/no-v-html-->
        <div v-html="text" />
    </dn-article>
</template>

<script>
import DnArticle from '~/patterns/organisms/article/article.vue';

export default {
    components: {
        DnArticle
    },

    props: {
        text: {
            type: String,
            required: true,
            default: ''
        }
    }
};
</script>
